import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import { Error } from "./pages/Error";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Explore from "./pages/Explore";
import Settings from "./pages/Settings";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import { GetJWT } from "./utils/CookieHelper";
import Exam from "./pages/Exam";
import Steps from "./pages/ExamSteps/Steps";
import { GetReturnUrl, RedirectToLogin } from "./utils/RedirectHelper";
import { getProfile, toggleLoading } from "./app/auth/actions";
import { ProtectedRoute } from "./components/ProtectedRoute";

const App = () => {
  console.log("v2");
  const { isLoadingRedux } = useSelector((store) => store.auth);
  <Route path="/exam/:id" element={<Exam />}>
    <Route index element={<Steps />} />
  </Route>;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <>
      <BrowserRouter>
        {isLoadingRedux && <Loader />}
        <Routes>
          {/* public routes */}
          <Route
            path="/xd"
            element={
              <ProtectedRoute>
                <Explore />
              </ProtectedRoute>
            }
          />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route path="/" element={<Home />} />
          </Route>

          <Route
            path="/exam/:id"
            element={
              <ProtectedRoute>
                <Exam />
              </ProtectedRoute>
            }
          >
            <Route index element={<Steps />} />
          </Route>
          <Route
            path="/login"
            element={
              <ProtectedRoute loginRequired={false}>
                <Login />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Error />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
