import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeExamStep } from "../../app/examActions/action";
import nextIcon from "../../assets/svg/icons/NEXTARROW.svg";
const Step1 = () => {
  const dispatch = useDispatch();
  const { activeExamData } = useSelector((store) => store.assessments);
  const { profileData } = useSelector((state) => state.auth);
  const ref = useRef();
  return (
    <div className="step step-1" ref={ref}>
      <div className="sub-title">
        <div className="gross-title">
          {profileData.user !== undefined
            ? "Merhaba" + " " + profileData.user.first_name + "!"
            : null}
        </div>
        <div>
          {activeExamData.company.name}{" "}
          {activeExamData.exam.category === "Personality Test"
            ? "İşlevsel Kişilik Envanteri testine"
            : "Genel Yetenek testine"}{" "}
          hoş geldin.
        </div>
        <div>
          Bu testi tamamlayarak yeteneklerini gösterebilir ve diğer adaylardan
          bir adım öne çıkarak farkını ortaya koyabilirsin!
        </div>
        <div className="wish">İyi şanslar!</div>
      </div>
      <div
        className="next-button-step transform-3"
        onClick={(e) => {
          dispatch(changeExamStep("second"));
          window.scroll(0, 0);
        }}
      >
        İleri <img src={nextIcon} alt="icon" />
      </div>
    </div>
  );
};

export default Step1;
