import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/actions";
import siteReducer from "./siteActions/actions";
import assessmentReducer from "./assessments/actions";
import examReducer from "./examActions/action";
import modalReducer from "../components/modals/modalActions";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    site: siteReducer,
    assessments: assessmentReducer,
    exam: examReducer,
    modals: modalReducer,
  },
});
