import React, { useEffect } from "react";
import { useState } from "react";

const Circle = ({ classname, children }) => {
  return <div className={classname}>{children}</div>;
};

const Timeline = ({ count = 4, isActive, setIsActive }) => {
  const [circle] = useState(count);
  const [width, setWidth] = useState(20);
  useEffect(() => {
    if (circle === isActive + 1) {
      setWidth((100 / (circle - 1)) * isActive);
    } else {
      setWidth((100 / (circle - 1)) * isActive + 5);
    }
  }, [circle, isActive]);
  const arr = [];
  for (let i = 0; i < circle; i++) {
    arr.push(
      <Circle key={i} classname={i <= isActive ? "circle active" : "circle"}>
        {i}
      </Circle>
    );
  }
  return (
    <>
      <div className="progressbar">
        {arr}
        <div className="progress" style={{ width: width + "%" }}></div>
      </div>
    </>
  );
};

export default Timeline;
