import React, { useState } from "react";
import arrow from "../assets/svg/icons/DROPDOWN_ARROW_DOWN.svg";
const DropdownBasicWebcam = (props) => {
  const {
    selected = { deviceId: "default", label: "test" },
    setSelected,
    options,
    placeHolder = "test",
    icon = false,
  } = props;
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => setShowOptions(!showOptions);

  return (
    <div className="dropdown-container">
      <div className="selected-option" onClick={toggleOptions}>
        {!!icon && <img src={icon} alt="icon" />}
        {(selected !== null && selected.label) || placeHolder}
        {showOptions ? (
          <img src={arrow} alt="icon" className="reverse" />
        ) : (
          <img src={arrow} alt="icon" />
        )}
      </div>
      {showOptions && (
        <ul className="options">
          {options.map((option) => (
            <li
              key={option.deviceId}
              className="option"
              onClick={() => {
                setSelected(option);
                toggleOptions();
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropdownBasicWebcam;
