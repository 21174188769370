import React, { useCallback, useEffect, useRef, useState } from "react";
import Timeline from "../../components/Timeline";
import next from "../../assets/svg/icons/NEXTARROW.svg";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { answerQuestion, updateStatus } from "../../app/assessments/actions";
import { toggleLoading } from "../../app/auth/actions";
export const ExamPage = () => {
  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  };

  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { activeExamData, activeConfig } = useSelector(
    (store) => store.assessments
  );
  const params = useParams();
  const [controller, setController] = useState(1);
  const { profileData } = useSelector((store) => store.auth);
  const [questionsPerPage, setQuestionPerPage] = useState(
    activeExamData.exam.category === "General Apitute Test" ? 1 : 10
  );
  const totalPages = Math.ceil(
    activeExamData.exam.questions.length / questionsPerPage
  );
  function getQuestionsForPage(pageNumber) {
    const startIndex = (pageNumber - 1) * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    return activeExamData.exam.questions.slice(startIndex, endIndex);
  }
  const [currentPage, setCurrentPage] = useState(
    activeConfig.last_page > 1 ? activeConfig.last_page - 1 : 1
  );
  const [currentQuestions, setCurrentQuestions] = useState(
    getQuestionsForPage(currentPage)
  );
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
    setCurrentQuestions(getQuestionsForPage(pageNumber));
  }
  const [answers, setAnswers] = useState({});
  function handleAnswerChange(questionId, answer) {
    setAnswers((prevAnswers) => {
      return {
        ...prevAnswers,
        [questionId]: answer,
      };
    });
  }
  const stableHeads = [
    "Kesinlikle Katılmıyorum",
    "Katılmıyorum",
    "Kararsızım",
    "Katılıyorum",
    "Kesinlikle Katılıyorum",
  ];
  const isAllAnswered = () => {
    for (let i = 0; i < currentQuestions.length; i++) {
      const questionId = currentQuestions[i].question_id.id;
      if (!answers.hasOwnProperty(questionId)) {
        return false;
      }
    }
    return true;
  };
  const [isNextOk, setIsNextOk] = useState(false);
  useEffect(() => {
    setIsNextOk(false);
    setIsNextOk(isAllAnswered());
  }, [answers, currentPage]);
  useEffect(() => {
    if (currentPage === 1) {
      dispatch(
        updateStatus({
          id: searchParams.get("id"),
          status: "inprogress",
          last_page: 2,
        })
      );
    } else {
      dispatch(
        updateStatus({
          id: searchParams.get("id"),
          status: "inprogress",
          last_page: currentPage + 1,
        })
      );
    }
  }, [currentPage]);
  const [soruSuresi, setSoruSuresi] = useState(0);
  // bir soruya kaç saniye harcandı
  useEffect(() => {
    const sayac = setInterval(() => {
      setSoruSuresi((soruSuresi) => soruSuresi + 1);
    }, 1000);
    return () => clearInterval(sayac);
  }, []);
  useEffect(() => {
    document.getElementById("scrollY").scroll(0, 0);
    document.getElementById("scroll").scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    console.log(currentPage);
  }, [currentPage]);
  function containsImgTag(str) {
    const regex = /<img[\s\S]*?>/i;
    return regex.test(str);
  }

  const PersonalExamTemplate = () => {
    return (
      <div className="inventory transition-3">
        <div className="bottom scroll">
          <div
            className="survey-container"
            id={"scrollY"}
            style={{ scrollBehavior: "smooth" }}
          >
            <div className="line head">
              <div className="row first-fix">
                <div className="text">{"No"}</div>
              </div>
              <div className="row big">
                <div className="text" style={{ fontWeight: 600 }}>
                  Soru
                </div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[0]}</div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[1]}</div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[2]}</div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[3]}</div>
              </div>
              <div className="row no-border">
                <div className="text">{stableHeads[4]}</div>
              </div>
            </div>
            {currentQuestions.map((question, index) => {
              return (
                <div
                  className={
                    index === currentQuestions.length - 1
                      ? "line no-border"
                      : "line"
                  }
                  key={index}
                >
                  <div className="row first-fix" data-responsive="No">
                    {index + 1 + (activeConfig.last_page - 2) * 10}
                  </div>
                  <div
                    className="row big"
                    data-responsive={
                      (activeConfig.last_page - 2) * 10 + index + 1
                    }
                    dangerouslySetInnerHTML={{
                      __html: question.question_id.content_rich,
                    }}
                  />
                  <>
                    {question.question_id.options.map((option, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            index === question.question_id.options.length - 1
                              ? "row no-border"
                              : "row"
                          }
                          data-responsive={stableHeads[index]}
                        >
                          {!isDesktop && <div className="border-supp" />}
                          <input
                            type="radio"
                            name={`answer-${question.question_id.id}`}
                            onChange={() => {
                              handleAnswerChange(
                                question.question_id.id,
                                option.option_id.id
                              );
                              let data = {
                                candidate: profileData.id,
                                assessment: params.id,
                                question_id: question.question_id.id,
                                option_id: option.option_id.id,
                                duration: soruSuresi,
                              };
                              dispatch(answerQuestion(data))
                                .unwrap()
                                .then((originalPromiseResult) => {})
                                .catch((rejectedValueOrSerializedError) => {
                                  console.log(rejectedValueOrSerializedError);
                                });
                              setSoruSuresi(0);
                            }}
                            checked={
                              answers[question.question_id.id] ===
                              option.option_id.id
                            }
                          />
                        </div>
                      );
                    })}
                  </>
                </div>
              );
            })}
          </div>
        </div>
        <div className="step-controller">
          <div className="info box">
            <div>
              <span>{activeExamData.exam.questions.length}</span> Sorudan
            </div>
            <div>
              <span>
                {`${
                  currentQuestions.length +
                  (activeConfig.last_page - 3) * 10 +
                  1
                }-${
                  currentQuestions.length +
                  (activeConfig.last_page - 3) * 10 +
                  10
                } `}
              </span>
              arası gösteriliyor.
            </div>
          </div>
          <div className="steps box">
            <Timeline
              count={totalPages}
              isActive={currentPage - 1}
              setIsActive={setController}
              circle={totalPages}
            />
          </div>
          {isNextOk ? (
            <>
              {currentPage === totalPages ? (
                <button
                  className="next-button transform-3"
                  onClick={(e) => {
                    dispatch(toggleLoading(true));
                    dispatch(
                      updateStatus({
                        id: searchParams.get("id"),
                        status: "completed",
                      })
                    )
                      .unwrap()
                      .then((originalPromiseResult) => {
                        setTimeout(() => {
                          navigate("/done");
                          dispatch(toggleLoading(false));
                        }, 2000);

                        document.getElementById("scrollY").scroll(0, 0);
                        document.getElementById("scroll").scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      })
                      .catch((rejectedValueOrSerializedError) => {
                        navigate("/done");
                        dispatch(toggleLoading(false));
                      });
                  }}
                >
                  Tamamla
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              ) : (
                <button
                  className="next-button transform-3"
                  disabled={currentPage === totalPages ? true : false}
                  onClick={(e) => {
                    handlePageChange(currentPage + 1);
                    setSoruSuresi(0);
                    window.scroll(0, 0);
                  }}
                >
                  İleri
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              )}
            </>
          ) : (
            <button className="next-button" disabled={true}>
              İleri
              <span>
                <img src={next} alt="icon" />
              </span>
            </button>
          )}
        </div>
      </div>
    );
  };
  const TestTemplate = () => {
    return (
      <>
        <div
          className={
            containsImgTag(
              currentQuestions[0].question_id.options[0].option_id.content_rich
            )
              ? "general-exam-wrapper test containImg"
              : "general-exam-wrapper test notContainImg"
          }
          id={"scrollY"}
          style={{ scrollBehavior: "smooth" }}
        >
          <div className="g-left">
            <strong>{activeConfig.last_page - 1}.</strong>
            <div
              className="question"
              dangerouslySetInnerHTML={{
                __html: currentQuestions[0].question_id.content_rich,
              }}
            />
          </div>
          <div className="seperator" />
          <div className="g-right">
            <div className="info">Sadece bir şık seçin.</div>
            <div className="radio-container">
              {currentQuestions[0].question_id.options.map((option, index) => {
                return (
                  <div key={index} className="radio-group">
                    <input
                      type="radio"
                      id={option.order}
                      name="group"
                      onChange={(e) => {
                        handleAnswerChange(
                          currentQuestions[0].question_id.id,
                          option.option_id.id
                        );
                        let data = {
                          candidate: profileData.id,
                          assessment: params.id,
                          question_id: currentQuestions[0].question_id.id,
                          option_id: option.option_id.id,
                          duration: soruSuresi,
                        };
                        dispatch(answerQuestion(data))
                          .unwrap()
                          .then((originalPromiseResult) => {})
                          .catch((rejectedValueOrSerializedError) => {
                            console.log(rejectedValueOrSerializedError);
                          });
                        setSoruSuresi(0);
                      }}
                      checked={
                        answers[currentQuestions[0].question_id.id] ===
                        option.option_id.id
                      }
                    />
                    <label
                      htmlFor={option.order}
                      dangerouslySetInnerHTML={{
                        __html: option.option_id.content_rich,
                      }}
                    ></label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="step-controller fix">
          <div className="info box">
            <div>
              <span>{activeExamData.exam.questions.length}</span> Sorudan
            </div>
            <div>
              <strong>{activeConfig.last_page - 1}.</strong> soru gösteriliyor.
            </div>
          </div>
          <div className="steps box" style={{ opacity: 0 }}></div>
          {isNextOk ? (
            <>
              {currentPage === totalPages ? (
                <button
                  className="next-button transform-3"
                  onClick={(e) => {
                    dispatch(toggleLoading(true));
                    dispatch(
                      updateStatus({
                        id: searchParams.get("id"),
                        status: "completed",
                      })
                    )
                      .unwrap()
                      .then((originalPromiseResult) => {
                        setTimeout(() => {
                          navigate("/done");
                          dispatch(toggleLoading(false));
                        }, 2000);
                      })
                      .catch((rejectedValueOrSerializedError) => {
                        navigate("/done");
                        dispatch(toggleLoading(false));
                      });
                  }}
                >
                  Tamamla
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              ) : (
                <button
                  className="next-button transform-3"
                  disabled={currentPage === totalPages ? true : false}
                  onClick={(e) => {
                    handlePageChange(currentPage + 1);
                    setSoruSuresi(0);
                    window.scroll(0, 0);
                  }}
                >
                  İleri
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              )}
            </>
          ) : (
            <button className="next-button" disabled={true}>
              İleri
              <span>
                <img src={next} alt="icon" />
              </span>
            </button>
          )}
        </div>
      </>
    );
  };
  return (
    <>
      {activeExamData.exam.category === "Personality Test" ? (
        <PersonalExamTemplate />
      ) : activeExamData.exam.category === "General Apitute Test" ? (
        <TestTemplate />
      ) : (
        "null"
      )}
    </>
  );
};
