import moment from "moment";
import React, { useEffect, useState } from "react";
import env from "react-dotenv";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAssessmentDetail,
  getHomeTableData,
} from "../app/assessments/actions";
import icon from "../assets/svg/COMPANY_PP_BIG_1.svg";
export const TableRow = ({ id, data }) => {
  require("moment/locale/tr.js");
  const dispatch = useDispatch();
  const [detailData, setDetailData] = useState(null);
  useEffect(() => {}, [data, id, detailData]);
  useEffect(() => {
    dispatch(getAssessmentDetail(id))
      .unwrap()
      .then((originalPromiseResult) => {
        setDetailData(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  }, []);
  return (
    <>
      {detailData !== null && (
        <>
          <div className="row">
            <div className="el icon">
              <div
                className="border-img"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={`${env.API_URL}/assets/${
                    detailData.company.icon !== null
                      ? detailData.company.icon.id
                      : null
                  }/alex_turner.png?key=webp-format`}
                  alt="logo"
                  className="responsive-img"
                />
              </div>
              <div>{detailData.company.name}</div>
            </div>
            <div className="el" data-responsive="Test Türü:">
              {detailData.exam.title}
            </div>
            <div className="el" data-responsive="Atanan Tarih:">
              {moment(data.assessment_date).format("DD MMMM YYYY")}
            </div>
            <div className="el " data-responsive="Tamamlanan Tarih:">
              {moment(data.completed_date).format("DD MMMM YYYY")}
            </div>
          </div>
          <div className="divider" />
        </>
      )}
    </>
  );
};
const TableHome = () => {
  const dispatch = useDispatch();
  const { homepageTableData } = useSelector((store) => store.assessments);
  useEffect(() => {
    dispatch(getHomeTableData());
  }, []);
  return (
    <div className="table company">
      <div className="table-box">
        {homepageTableData.length > 0 ? (
          <>
            <div className="row head">
              <div className="el">Kurum</div>
              <div className="el">Test Türü</div>
              <div className="el">Atanan Tarih</div>
              <div className="el ">Tamamlanan Tarih</div>
            </div>
            <div className="divider desktop" style={{ opacity: 1 }} />
            {homepageTableData.map((tableData, index) => (
              <div key={index}>
                <TableRow id={tableData.assessment_id} data={tableData} />
              </div>
            ))}
          </>
        ) : (
          <>
            <div className="row head">
              <div className="el">Kurum</div>
              <div className="el">Test Türü</div>
              <div className="el">Atanan Tarih</div>
              <div className="el ">Tamamlanan Tarih</div>
            </div>
            <div className="divider desktop" style={{ opacity: 1 }} />{" "}
            <>
              <div className="row">
                <div className="el icon">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      opacity: 0.6,
                      textAlign: "center",
                    }}
                  >
                    Tamamlanmış testiniz bulunmamaktadır!
                  </div>
                </div>
              </div>
              <div className="divider" />
            </>
          </>
        )}
      </div>
    </div>
  );
};

export default TableHome;
