import Cookies from "universal-cookie";

export const cookie = new Cookies();

export function GetJWT() {
  var accessToken = cookie.get("accessToken");
  var refreshToken = cookie.get("refreshToken");
  return {
    accessToken: accessToken === undefined ? false : `Bearer ${accessToken}`,
    refreshToken: refreshToken === undefined ? false : refreshToken,
    accessTokenLogout: accessToken,
  };
}

export function setJWT(payload) {
  let cookieExpires = new Date(Date.now() + payload.expires);
  cookie.set("accessToken", payload.access_token, {
    path: "/",
    expires: cookieExpires,
  });
  cookie.set("refreshToken", payload.refresh_token, {
    path: "/",
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24),
  });
}
