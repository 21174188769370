import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DropdownBasicWebcam from "../../components/DropdownBasicWebcam";
import { changeExamStep } from "../../app/examActions/action";
import nextIcon from "../../assets/svg/icons/NEXTARROW.svg";
import cam from "../../assets/svg/icons/CAM_ICON.svg";
import mic from "../../assets/svg/icons/MIC_ICON.svg";
import rec from "../../assets/svg/icons/REC_ICON.svg";
import play from "../../assets/svg/icons/PLAY_ICON.svg";
const Step3 = () => {
  const { activeExamStep } = useSelector((store) => store.exam);
  const dispatch = useDispatch();
  const [recording, setRecording] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const videoRef = useRef(null);
  const recordRef = useRef(null);
  const streamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  const getDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      console.log(devices.filter((device) => device.kind === "videoinput"));
      console.log(devices.filter((device) => device.kind === "audioinput"));
      setVideoDevices(devices.filter((device) => device.kind === "videoinput"));
      setAudioDevices(devices.filter((device) => device.kind === "audioinput"));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (selectedVideoDevice === null || selectedAudioDevice === null) return;
    startCam();
  }, [selectedVideoDevice]);
  const startCam = async () => {
    const constraints = {
      video: {
        deviceId: { exact: selectedVideoDevice.deviceId },
      },
      audio: {
        deviceId: { exact: selectedAudioDevice.deviceId },
      },
    };
    streamRef.current = await navigator.mediaDevices.getUserMedia(constraints);
    videoRef.current.srcObject = streamRef.current;
  };
  const handleStartRecording = async () => {
    setRecordedBlob(null);
    try {
      const constraints = {
        video: {
          deviceId: { exact: selectedVideoDevice.deviceId },
        },
        audio: {
          deviceId: { exact: selectedAudioDevice.deviceId },
        },
      };
      streamRef.current = await navigator.mediaDevices.getUserMedia(
        constraints
      );
      videoRef.current.srcObject = streamRef.current;
      mediaRecorderRef.current = new MediaRecorder(streamRef.current);
      mediaRecorderRef.current.start();
      const chunks = [];
      mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
        chunks.push(event.data);
      });
      mediaRecorderRef.current.addEventListener("stop", () => {
        const recordedBlob = new Blob(chunks, {
          type: mediaRecorderRef.current.mimeType,
        });
        setRecordedBlob(URL.createObjectURL(recordedBlob));
      });
      setRecording(true);
    } catch (error) {
      console.error(error);
      alert("Please Select Devices First!");
    }
  };
  const handleStopRecording = () => {
    mediaRecorderRef.current.stop();
    streamRef.current.getTracks().forEach((track) => track.stop());
    setRecording(false);
  };
  async function checkPermissions() {
    try {
      const videoPerm = await navigator.permissions.query({ name: "camera" });
      const audioPerm = await navigator.permissions.query({
        name: "microphone",
      });

      if (videoPerm.state === "granted" && audioPerm.state === "granted") {
        console.log("User has granted camera and microphone permissions");
      } else {
        console.log("User has not granted camera and microphone permissions");
      }
    } catch (error) {
      console.error("Permission check failed: ", error);
    }
  }
  useEffect(() => {
    checkPermissions();
  }, []);
  return (
    <div className="step step-3">
      <div className="title">
        <div>Teste başlamaya hazır olduğunuzdan emin olalım!</div>
        <span>Bu test sesli ve görüntülü sorular içermektedir.</span>
      </div>
      <div className="config-box">
        <div className="left">
          <div className="title">
            Kamera ve Mikrofon Kurulumu
            <span />
          </div>
          <div className="rules-bottom">
            <div className="info">
              Hiri’nin güvenlikli test altyapısında adayların kamera görüntüleri
              test süresince kaydedilmektedir. Kamera ve mikrofonunuzu
              kullanarak soruları bir video ya da ses kaydı ile yanıtlamanız
              istenecektir. Bu sebeple kameranızın ve mikrofonunuzun düzgün
              çalıştığından emin olmalısınız. Bu adımda kaydedeceğiniz deneme
              videoları saklanmaz ve kurumlara gösterilmez.
            </div>
            <div className="rules">
              <div>
                1. Sağ üst kısımda bulunan mikrofon ve kamera seçim menüsünden
                cihaz seçimi yapın.
              </div>
              <div>
                2. Tarayıcınızda “Kamera ve Mikrofon Erişimi”ne dair bir uyarı
                çıkacaktır. “İzin Ver”e tıklayın. Böylece sesli veya video ile
                yanıtlanan soruları tamamlayabileceksiniz.
              </div>
              <div>
                3. <span>Deneme Kaydı Başlat</span> butonuna tıklayın ve
                konuşmaya başlayın. Video kaydını tamamlamak için{" "}
                <span>Kaydı Tamamla</span> butonuna tıklayın.
              </div>
              <div>
                4. <span>Kaydı Oynat</span> butonuna tıklayın ve kaydınızda ses
                ve görüntünün sorunsuz olduğundan emin olun.
              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="right">
          <div className="source-select-box">
            <DropdownBasicWebcam
              icon={mic}
              placeHolder="Mikrofon Seç (Default)"
              selected={selectedAudioDevice}
              setSelected={setSelectedAudioDevice}
              options={audioDevices}
            />
            <DropdownBasicWebcam
              icon={cam}
              placeHolder="Kamera Seç (Default)"
              selected={selectedVideoDevice}
              setSelected={setSelectedVideoDevice}
              options={videoDevices}
            />
          </div>
          <div className="cam-area">
            {recordedBlob === null && <video ref={videoRef} autoPlay={true} />}
            {recordedBlob ? (
              <video src={recordedBlob} ref={recordRef} controls={false} />
            ) : null}
          </div>
          <div className="buttons">
            {!recording ? (
              <div className="record" onClick={handleStartRecording}>
                <img src={rec} alt="icon" /> Deneme Kaydı Başlat
              </div>
            ) : (
              <div className="record" onClick={handleStopRecording}>
                <img src={rec} alt="icon" /> Deneme Kaydını Durdur
              </div>
            )}
            {recordedBlob ? (
              <div
                className="play"
                onClick={(e) => {
                  recordRef.current.play();
                }}
              >
                <img src={play} alt="icon" />
                Kaydı Oynat
              </div>
            ) : (
              <div className="play disabled">
                <img src={play} alt="icon" />
                Kaydı Oynat
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="next-button-step transform-3"
        onClick={(e) => {
          dispatch(changeExamStep("fakeInfo"));
          window.scroll(0, 0);
        }}
      >
        İleri <img src={nextIcon} alt="icon" />
      </div>
    </div>
  );
};

export default Step3;
