import React, { useEffect, useState } from "react";
import env from "react-dotenv";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getExam, updateStatus } from "../app/assessments/actions";
import Countdown from "../components/TimerComponents/Countdown";
const Exam = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { activeExamData, activeConfig, uniqueMixKey } = useSelector(
    (store) => store.assessments
  );
  const { profileData } = useSelector((state) => state.auth);
  function strToNum(str) {
    let num = "";
    let leadingZero = true;
    for (let i = 0; i < str.length; i++) {
      if (!isNaN(str[i])) {
        if (leadingZero && str[i] === "0") {
          continue;
        }
        num += str[i];
        leadingZero = false;
      }
    }
    let firstFiveDigits = Number(num.substring(0, 1));
    return firstFiveDigits;
  }

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  };
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // aktif sınavı çekiyorum - sayfa her yenilendiğinde çalışacak.
  useEffect(() => {
    let callConfig = {
      assessment_id: params.id,
      id: searchParams.get("id"),
      seed: profileData.id,
    };
    dispatch(getExam(callConfig))
      // aktif sınav geldikten sonra config bilgisini çekip activeExamData altına kaydediyorum
      .unwrap()
      .then((originalPromiseResult) => {
        // assessment id ve sınavın unique idsini yolluyorum. config alabilmek için. aslında status güncellemiyorum sadece config bilgisini alıyorum
        dispatch(updateStatus({ id: searchParams.get("id") }))
          .unwrap()
          .then((originalPromiseResult) => {
            console.log(originalPromiseResult.data);
            // sınavın config bilgisine göre kullanıcıyı kaldığı yerden devam ettir
            dispatch(
              updateStatus({
                id: searchParams.get("id"),
                last_page: originalPromiseResult.data.last_page,
              })
            );
          })
          .catch((rejectedValueOrSerializedError) => {
            // examı çekerken bi hata alırsam ana sayfaya yönlendiriyorum
            navigate("/");
            console.log(rejectedValueOrSerializedError);
          });
      })
      .catch((rejectedValueOrSerializedError) => {
        // examı çekerken bi hata alırsam ana sayfaya yönlendiriyorum
        navigate("/");
        console.log(rejectedValueOrSerializedError);
      });
  }, []);
  // sayfa yenilenirse ya da kapatılırsa en son confiği servise yolla
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      dispatch(
        updateStatus({
          id: searchParams.get("id"),
          status: "paused",
          last_page: activeConfig.last_page,
        })
      );

      // event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  // kullanıcı tarayıcıda geri tuşuna basamasın
  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    const handleBack = () => {
      window.location.reload();
    };
    window.addEventListener("popstate", handleBack);
    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, []);

  // mobil tarayıcılar için kapatma senaryosu
  const [isPageActive, setPageActive] = useState(true);
  useEffect(() => {
    const blurHandler = () => setPageActive(false);
    const focusHandler = () => setPageActive(true);
    window.addEventListener("blur", blurHandler);
    window.addEventListener("focus", focusHandler);
    return () => {
      window.removeEventListener("blur", blurHandler);
      window.removeEventListener("focus", focusHandler);
    };
  }, []);
  useEffect(() => {
    if (isDesktop) return;
    if (activeConfig.last_page > 1) {
      if (!isPageActive) {
        dispatch(
          updateStatus({
            id: searchParams.get("id"),
            status: "paused",
          })
        )
          .unwrap()
          .then((originalPromiseResult) => {
            navigate("/");
          })
          .catch((rejectedValueOrSerializedError) => {
            console.log(rejectedValueOrSerializedError);
          });
      } else {
        dispatch(
          updateStatus({
            id: searchParams.get("id"),
            status: "inprogress",
          })
        );
      }
    } else {
      return;
    }
  }, [isPageActive, isDesktop]);

  return (
    <>
      {activeExamData.company !== undefined && (
        <div className="hiri-container exam scroll" id="scroll">
          <div className="inventory transition-3">
            <div className="top transition-3">
              <div className="left box ">
                <div className="page-title">
                  {activeExamData.exam.category === "Personality Test"
                    ? "İşlevsel Kişilik Envanteri"
                    : "Genel Yetenek"}
                </div>
                <div className="customer-badge">
                  <div className="title-right">
                    <img
                      src={`${env.API_URL}/assets/${
                        activeExamData.company.icon !== null
                          ? activeExamData.company.icon.id
                          : null
                      }/alex_turner.png?key=webp-format`}
                      alt="icon"
                    />
                    <div>{activeExamData.company.name}</div>
                  </div>
                </div>
              </div>
              <div className="right box purple">
                <div className="timer">
                  <div className="top">
                    {activeConfig.status === "inprogress"
                      ? "Kalan Süre"
                      : "Sınav Süresi"}{" "}
                  </div>
                  <div className="bottom">
                    {activeConfig.status === "inprogress" ? (
                      <Countdown
                        initialTime={Math.round(activeConfig.duration * 60)}
                        onComplete={(e) => {
                          dispatch(
                            updateStatus({
                              id: searchParams.get("id"),
                              status: "completed",
                            })
                          )
                            .unwrap()
                            .then((originalPromiseResult) => {
                              navigate("/canceled");
                            })
                            .catch((rejectedValueOrSerializedError) => {
                              navigate("/canceled");
                            });
                        }}
                      />
                    ) : (
                      activeExamData.exam.duration + ":00"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom box exam">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Exam;
