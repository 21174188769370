import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ad from "../assets/svg/icons/ARROW-DOWN-ICON.svg";
import au from "../assets/svg/icons/ARROW-UP-ICON.svg";
import { useDraggable } from "react-use-draggable-scroll";
import AssessmentCard from "../components/AssessmentCard";
import TableHome from "../components/TableHome";
import { getAssessmentList } from "../app/assessments/actions";
import { useDispatch } from "react-redux";
const Home = () => {
  const { activeAssessmentList } = useSelector((store) => store.assessments);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAssessmentList());
  }, []);
  useEffect(() => {
    if (activeAssessmentList.length > 0) {
      setAssessmentListData(activeAssessmentList);
    }
  }, [activeAssessmentList]);
  const [assessmentListData, setAssessmentListData] = useState([]);
  const ref = useRef();
  const { events } = useDraggable(ref);
  const [filterMode, setFilterMode] = useState(true);
  return (
    <>
      <div className="title">
        <div>Aktif Testleriniz</div>
        <div
          className="filter"
          onClick={(e) => {
            setFilterMode(!filterMode);
          }}
        >
          <div>Sırala:</div>
          <div>
            {filterMode ? "Tarihe Göre (Yeni) " : "Tarihe Göre (Eski)"}
            <div>
              <img src={au} alt="icon" />
              <img src={ad} alt="icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="cards" {...events} ref={ref}>
        {assessmentListData.map((assessment, index) => (
          <AssessmentCard key={index} assessment={assessment} />
        ))}
      </div>
      <div className="title fix">
        <div>Tamamlanmış Testleriniz</div>
      </div>
      <TableHome />
    </>
  );
};

export default Home;
