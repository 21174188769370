import React, { useEffect, useRef, useState } from "react";
import Content from "./Content";
import Menu from "./Menu";
import TopSearchBar from "./TopSearchBar";

import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
const Layout = () => {
  const { menuOpen } = useSelector((state) => state.site);
  const { isLoadingRedux } = useSelector((store) => store.auth);
  return (
    <>
      <div
        style={isLoadingRedux ? { overflow: "hidden" } : null}
        className={menuOpen ? "hiri-layout active" : "hiri-layout"}
      >
        <div className="layout-left">
          <Menu />
        </div>
        <div className="layout-right">
          <TopSearchBar />
          <Content>
            <Outlet />
          </Content>
        </div>
      </div>
    </>
  );
};

export default Layout;
