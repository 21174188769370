import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { updateStatus } from "../../app/assessments/actions";
function Countdown({ initialTime, onComplete }) {
  const [remainingTime, setRemainingTime] = useState(initialTime);
  const [stamp, setStamp] = useState(initialTime);
  const [searchParams] = useSearchParams();
  const { activeExamData } = useSelector((store) => store.assessments);
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    if (remainingTime <= 0) {
      onComplete();
      return;
    }
    const intervalId = setInterval(() => {
      setRemainingTime((prevRemainingTime) => prevRemainingTime - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [remainingTime, onComplete]);
  useEffect(() => {
    if (remainingTime <= 0) {
      return;
    }

    const intervalId = setInterval(() => {
      dispatch(
        updateStatus({
          id: searchParams.get("id"),
          duration: remainingTime / 60,
          spent_time: activeExamData.exam.duration * 60 - remainingTime,
          left_time: remainingTime,
        })
      );
      setStamp((prevRemainingTime) => prevRemainingTime - 1);
    }, 2000);
    return () => clearInterval(intervalId);
  }, [stamp, onComplete]);
  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);
  const seconds = remainingTime % 60;
  const displayHours = hours > 0 ? hours.toString().padStart(2, "0") : "";
  const displayMinutes = minutes.toString().padStart(2, "0");
  const displaySeconds = seconds.toString().padStart(2, "0");
  return (
    <>
      {hours === 0 ? null : (
        <>
          <div> {displayHours}</div>
          <div style={{ width: "auto" }}>:</div>
        </>
      )}
      <div> {displayMinutes}</div>
      <div style={{ width: "auto" }}>:</div>
      <div> {displaySeconds}</div>
    </>
  );
}
export default Countdown;
