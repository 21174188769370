import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeExamStep } from "../../app/examActions/action";
import nextIcon from "../../assets/svg/icons/NEXTARROW.svg";
const FakeExamInformation = () => {
  const { activeExamData } = useSelector((store) => store.assessments);
  const dispatch = useDispatch();
  return (
    <div className="step step-1">
      <div className="sub-title">
        <div className="gross-title">Teste Başlamadan Önce!</div>
        <div>
          {" "}
          {activeExamData.exam.category === "Personality Test"
            ? "4 "
            : "2 "}{" "}
          örnek sorudan oluşan hazırlanma adımını tamamlamalısınız.
        </div>
        <div>
          Bu adımı tamamladığınızda{" "}
          {activeExamData.exam.category === "Personality Test"
            ? "İşlevsel Kişilik Envanteri "
            : "Genel Yetenek "}
          testine hazır olacaksınız.
        </div>
        <div>Tüm sorulara cevap vererek testi bitirin.</div>
        <div className="wish">
          Örnek soruları görüntülemek için aşağıdaki İLERİ butonuna tıklayın.
        </div>
      </div>
      <div
        className="next-button-step transform-3"
        onClick={(e) => {
          dispatch(changeExamStep("fakeExam"));
          window.scroll(0, 0);
        }}
      >
        İleri <img src={nextIcon} alt="icon" />
      </div>
    </div>
  );
};

export default FakeExamInformation;
